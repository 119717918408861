import React, { useState } from 'react'
import faqConfig from './faqConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faClipboardQuestion } from '@fortawesome/free-solid-svg-icons'

function Faq() {
    const {questions} = faqConfig
    return (
        <div className='flex flex-col gap-y-4 px-2'>
            <FontAwesomeIcon icon={faClipboardQuestion} className='h-20'/>
            {questions.map(questionConfig => {
                return (
                    <QuestionAndAnswer questionConfig={questionConfig} />
                )
            })}
        </div>
    )
}

function QuestionAndAnswer({questionConfig}) {
    const {question, answer} = questionConfig
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className='flex flex-col overflow-hidden rounded-lg' onClick={() => setIsOpen(!isOpen)}>
            <div className={`bg-blue-600 p-4 font-bold flex justify-between ${isOpen ? 'border-b-2' : ''}`}>
                <p className='text-2xl'>{question}</p>
                <button onClick={() => setIsOpen(!isOpen)} className='text-white'>
                    {isOpen ? <FontAwesomeIcon icon={faArrowUp} className='my-auto'/> :<FontAwesomeIcon icon={faArrowDown} className='my-auto' />}
                </button>
            </div>
            {isOpen && <div className='bg-neutral-50 p-4 text-black text-xl'>{answer}</div>}
        </div>
    )
}

export default Faq