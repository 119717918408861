import React from 'react'
import Background from '../background/Background'

function Layout({children}) {
    return (
        <div className='max-w-screen h-full min-h-screen py-4 text-white flex flex-col sm:px-20 sm:py-4'>
            <h1 className='text-center text-3xl font-semibold mb-4'>TopSpot Partners</h1>           
            {children}
        </div>
    )
}

export default Layout