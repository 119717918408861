import { faCode, faSearch, faArrowTrendUp } from "@fortawesome/free-solid-svg-icons"

const howConfig = {
    offer: {
        text: 'Bij Topspot Partners bieden we meer dan alleen diensten; we bieden resultaten die meetbaar zijn. We zorgen ervoor dat jouw Bol.com producten hoger in de zoekresultaten verschijnen, waardoor je meer sales genereert! Met onze kennis en de variabelen die Bol.com gebruikt, kunnen we een gegarandeerde top 10, top 5 en zelfs #1 positie bereiken op het zoekwoord met het grootse zoekvolume voor al onze klanten! Onze uitgebreide suite van services omvat zoekwoordoptimalisatie, productbeschrijving verbetering, strategisch prijsbeheer en meer. We werken nauw samen met jou om jouw unieke merkidentiteit te begrijpen en te integreren in elke stap van het proces.'
    },
    stepOne: {
        title: 'Stap 1',
        text: 'Kies het product dat je wilt laten boosten en stuur ons de product listing URL door.',
        icon: faCode
    },
    stepTwo: {
        title: 'Stap 2',
        text: 'Bepaal de zoekwoorden waarop je gevonden wilt worden. Kies de 2 zoekwoorden met het hoogste volume voor jouw product.',
        icon: faSearch
    },
    stepThree: {
        title: 'Stap 3',
        text: 'Laat je product tot 7 dagen GRATIS door ons boosten. Binnen enkele dagen zie je je listing omhoog schieten en merk je al resultaat.',
        icon: faArrowTrendUp
    },
}

// module.exports = howConfig
export default howConfig