const faqConfig = {
    questions: [
        {
            question: 'Hoe snel zie ik resultaat?',
            answer: 'Binnen 10 dagen rangschikken wij jouw product binnen de top 10. Meestal zie je binnen enkele dagen al resultaten.'
        },
        {
            question: 'Kan ik dit gratis uitproberen?',
            answer: 'Zeker! Je kunt de Topspot service 7 dagen lang gratis uitproberen zonder enige verplichting. Neem contact met ons op en we stellen gratis een campagne voor je op.'
        },
        {
            question: 'Geld-terug-garantie: Hoe werkt dit?',
            answer: 'Het is simpel. Als jouw productpagina bij de start van de campagne aan de voorwaarden voldoet en niet minstens 2 van de 4 weken de door jou gekozen ranking behaalt, ontvang je het volledige aankoopbedrag terug.'
        },
        {
            question: 'Hoe start ik een campagne?',
            answer: 'Neem contact met ons op en stuur de URL van jouw productlisting en de gewenste zoekwoorden naar ons op. We hebben geen inloggegevens van je verkopersaccount nodig.'
        },
        {
            question: 'Wat gebeurt er na afloop van de campagne?',
            answer: 'Als je besluit de campagne te beëindigen, stopt de Topspot service. De exacte positie na afloop is lastig te voorspellen, afhankelijk van concurrentie en prijswijzigingen. Uit ervaring zien we dat een productlisting doorgaans binnen 7 tot 30 dagen na het beëindigen van de campagne weer rond zijn oude positie rankt.'
        },
    ]
}

module.exports = faqConfig