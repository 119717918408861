import React from 'react'
import Calendly from '../calendly/Calendly'
import homeConfig from './homeConfig'
import { faArrowTrendUp, faRankingStar, faAward, faTruckFast, faPlus, faEquals } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Home() {
    const {aboutUs, expertise, offer, commitment, appointment} = homeConfig
    return (
        <div className='w-full text-black'>
            <section className='flex justify-between items-center border-t-2 uppercase text-md sm:px-28 sm:text-xl'>
                <div className='h-20 flex items-center'>
                    <p className='mx-auto font-bold text-white text-center'>Jouw product</p>
                </div>
                <FontAwesomeIcon icon={faPlus} className='my-auto text-blue-600'/>
                <div className='h-20 flex items-center'>
                    <p className='mx-auto font-bold text-white text-center'>Onze service</p>
                </div>
                <FontAwesomeIcon icon={faEquals} className='my-auto text-blue-600'/>
                <div className='h-20 flex items-center'>
                    <p className='mx-auto font-bold text-white text-center'>Optimale ranking</p>
                </div>
            </section>
            <div className='w-full bg-white p-4 flex gap-x-8 sm:rounded-md'>
                <div className='w-1/6 sm:pt-12'>
                    <FontAwesomeIcon icon={faTruckFast} className='w-full h-full text-blue-600'/>
                </div>
                <p className='w-full text-xl text-center m-auto leading-loose sm:text-4xl'>Dé pioniers in het optimaliseren van online zichtbaarheid en verkoopsuccess voor Bol.com verkooppartners.</p>
                {/* <p className=''>{aboutUs.text}</p> */}
            </div>
            <section className='w-full h-full flex flex-col gap-y-2 sm:grid sm:grid-cols-4 my-4'>
                <div className='col-span-3 my-auto p-4 text-white'>
                    <p>{appointment.text}</p>
                </div>
                <div className='h-full w-full flex justify-center my-4'>
                    <Calendly />
                </div>
            </section>
            <section className='w-full grid grid-cols-4 my-4 gap-x-4 bg-white sm:rounded-md sm:my-0'>
                <div className='col-span-3 my-auto p-4'>
                    <p className='font-semibold text-lg text-blue-600'>{expertise.header}</p>
                    <p>{expertise.text}</p>
                </div>
                <div className='h-full w-full flex justify-center'>
                    <FontAwesomeIcon icon={faAward} className='w-1/2 h-3/4 my-auto text-blue-600'/>
                </div>
            </section>
        </div>
    )
}

export default Home