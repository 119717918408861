import React, { useState } from 'react'
import pricingConfig from './pricingConfig'

function Pricing() {

    return (
        <div className='flex flex-col mt-8'>
            <Items />
            <Details />
            <Notes />
        </div>
    )
}

function Details() {
    const [showDetails, setShowDetails] = useState(false)
    const {discounts} = pricingConfig
    if(showDetails) {
        return (
            <div className='flex flex-col w-full mx-auto py-4 gap-y-4 px-2 sm:gap-x-4 sm:flex-row'>
                <table className='w-full h-full text-center rounded-lg shadow-lg border-2 border-blue-600'>
                    <thead>
                        <tr className='font-bold text-lg bg-white text-black border-b-2 border-blue-600 italic'>
                            <th className='py-2'>Periode</th>
                            <th className='py-2'>Prijs (Korting %)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {discounts.periods.map((period, index) => (
                            <tr key={index} className={`py-2 text-lg text-black ${index % 2 === 0 ? 'bg-blue-200' : 'bg-white text-black'}`}>
                                <td className='py-2'>{period.period}</td>
                                <td className='py-2'>{`€ ${period.price} (${period.discount})`}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <table className='w-full h-full text-center rounded-lg shadow-lg border-2 border-blue-600'>
                    <thead>
                        <tr className='font-bold text-lg bg-white text-black border-b-2 border-blue-600 italic'>
                            <th className='py-2'>Zoekwoorden</th>
                            <th className='py-2'>Prijs / listing</th>
                        </tr>
                    </thead>
                    <tbody>
                        {discounts.searchTerms.map((period, index) => (
                            <tr key={index} className={`py-2 text-lg text-black ${index % 2 === 0 ? 'bg-blue-200' : 'bg-white'}`}>
                                <td className='py-2'>{period.numSearchTerms}</td>
                                <td className='py-2'>{`€ ${period.price}`}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
    return (
        <button 
            onClick={() => setShowDetails(true)}
            className='mx-auto bg-blue-600 w-4/5 py-4 mt-4 rounded-full font-semibold text-lg shadow-lg sm:w-1/4'>+ Details</button>
    )
}

function Items() {
    const {items} = pricingConfig
    return (
        <div className='flex flex-col'>
            {items.map((item, index) => {
                return (
                    <Item itemConfig={item} key={index} index={index} />
                )
            })}
        </div>
    )
}

function Item({itemConfig, index}) {
    return (
        <div className={`flex justify-between px-4 py-8 tracking-wider sm:px-8 ${index % 2 === 0 ? 'bg-white text-black sm:rounded-md' : ''}`}>
            <p className='font-bold text-xl uppercase'>{itemConfig.text}</p>
            <p className='text-2xl'>v.a. € {itemConfig.price},-</p>
        </div>
    )
}

function Notes() {
    const {notes} = pricingConfig
    return (
        <ul className='text-blue-600 list-disc text-lg ml-8 mt-10 uppercase tracking-wider'>
            {notes.map((note, index) => {
                return (
                    <li key={index}>{note}</li>
                )
            })}
        </ul>
    )
}

export default Pricing