import React from 'react'
import { InlineWidget, PopupButton } from 'react-calendly'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

function Calendly() {
    return (
        <div className='h-full p-0'>
            {/* <InlineWidget url='https://calendly.com/ranking-genius'/> */}
            <PopupButton 
                url='https://calendly.com/ranking-genius' 
                rootElement={document.getElementById('root')}
                className='bg-blue-600 text-white uppercase w-full h-24 rounded-lg px-8 font-bold'
                text='Plan een kennismaking in' />
        </div>
    )
}

export default Calendly