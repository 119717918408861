import { useState } from "react";
import Layout from "./components/layout/Layout";
import Home from "./components/home/Home";
import How from "./components/how/How";
import Faq from "./components/faq/Faq";
import Pricing from "./components/pricing/Pricing";
import Background from "./components/background/Background";
import Footer from "./components/layout/Footer";

function App() {
    const [currentTab, setCurrentTab] = useState('home')

    return (
        <Layout>
            <Background />
            <TabNavigator currentTab={currentTab} setCurrentTab={setCurrentTab}/>
            {currentTab === 'home' && <Home />}
            {currentTab === 'how' && <How />}
            {currentTab === 'pricing' && <Pricing />}
            {currentTab === 'faq' && <Faq />}
            <Footer />
        </Layout>
    );
}

function TabNavigator({currentTab, setCurrentTab}) {
    return (
        <div className='w-full'>
            <div className='w-[90%] sm:w-4/5 grid grid-cols-4 mx-auto rounded-lg overflow-hidden shadow-md shadow-blue-300 mb-2 sm:mb-4'>
                <Tab text='Home' id='home' currentTab={currentTab} setCurrentTab={setCurrentTab}/>
                <Tab text='Hoe werkt het?' id='how' currentTab={currentTab} setCurrentTab={setCurrentTab}/>
                <Tab text='Pricing' id='pricing' currentTab={currentTab} setCurrentTab={setCurrentTab}/>
                <Tab text='FAQ' id='faq' currentTab={currentTab} setCurrentTab={setCurrentTab}/>
            </div>
        </div>
    )
}

function Tab({text, id, style='', currentTab, setCurrentTab}) {
    const isSelected = currentTab === id ? 'bg-blue-600/70 italic font-semibold text-white' : 'bg-white text-blue-600'
    return (
        <button 
            onClick={() => setCurrentTab(id)}
            className={`text-md uppercase ${isSelected} py-2 px-2 ${style} sm:text-lg sm:py-4`}>
            {text}
        </button>
    )
}

export default App;
