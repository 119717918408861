import React from 'react'

function Footer() {
    return (
        <div className='h-20 bg-white text-black mb-0 mt-4 items-center flex rounded-md'>
            <ul className='mx-auto'>
                <li className='hover:text-black/70'>
                    <a href='https://d1g0hhwtvhk8p7.cloudfront.net/topspot-algemene-voorwaardenpdf.pdf'>Algemene voorwaarden</a>
                </li>
            </ul>
        </div>
    )
}

export default Footer