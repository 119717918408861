const pricingConfig = {
    items: [
        {
            text: '1-4 Producten',
            price: 132
        },
        {
            text: '5-9 Producten',
            price: 115
        },
        {
            text: '10+ Producten',
            price: 99
        },
    ],
    notes: [
        'Prijzen zijn voor Belgie en Nederland in 1',
        'Prijzen zijn maandelijks per listing',
        'Niet-goed-geld-terug-garantie'
    ],
    discounts: {
        periods: [
            {period: '3 maanden', price: '356,40', discount: '10%'},
            {period: '6 maanden', price: 594, discount: '25%'},
        ],
        searchTerms: [
            {numSearchTerms: 2, price: 132},
            {numSearchTerms: 3, price: 149},
            {numSearchTerms: 4, price: 169},
        ]
    }
}

module.exports = pricingConfig