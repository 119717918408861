const homeConfig = {
    aboutUs: {
        text: 'Welkom bij Topspot Partners, dé pioniers in het optimaliseren van online zichtbaarheid en verkoopsucces voor Bol.com verkooppartners. Onze passie ligt in het helpen van bedrijven zoals die van jou om de concurrentie voor te blijven en een prominente plek te veroveren in de dynamische wereld van e-commerce.'
    },
    expertise: {
        header: 'Onze expertise',
        text: 'Met jarenlange ervaring in e-commerce en digitale marketing begrijpen we als geen ander hoe essentieel het is om op te vallen in een drukke online marktplaats als bol.com. Ons team van deskundigen is toegewijd aan het analyseren van de nieuwste trends en algoritme-updates om ervoor te zorgen dat jouw producten de aandacht krijgen die ze verdienen. We combineren geavanceerde technologie met creatieve benaderingen om de optimale \'topspot\' voor jouw producten te verzekeren.'
    },
    // offer: {
    //     header: 'Wat wij bieden',
    //     text: `Bij Topspot Partners bieden we meer dan alleen diensten; we bieden resultaten die meetbaar zijn. We zorgen ervoor dat jouw Bol.com producten hoger in de zoekresultaten verschijnen, waardoor je meer sales genereert! Met onze kennis en de variabelen die Bol.com gebruikt, kunnen we een gegarandeerde top 10, top 5 en zelfs #1 positie bereiken op het zoekwoord met het grootse zoekvolume voor al onze klanten!
    //     Onze uitgebreide suite van services omvat zoekwoordoptimalisatie, productbeschrijving verbetering, strategisch prijsbeheer en meer. We werken nauw samen met jou om jouw unieke merkidentiteit te begrijpen en te integreren in elke stap van het proces.
    //     `
    // },
    // commitment: {
    //     header: 'Onze toewijding',
    //     text: 'We zijn trots op onze toewijding aan klanttevredenheid en succes. Jouw doelen worden onze doelen en we rusten niet voordat we samen de gewenste resultaten hebben bereikt. Jouw succesverhaal is ons succesverhaal, en we kijken ernaar uit om samen te groeien in deze opwindende reis.'
    // },
    appointment: {
        text: 'Ben je klaar om jouw producten naar de top te brengen? Plan vandaag nog gratis een vrijblijvend gesprek in met één van onze Topspotters en ontdek hoe we jou kunnen helpen om uit te blinken in de wereld van Bol.com. Samen zorgen we ervoor dat jouw producten de aandacht krijgen die ze verdienen.'
    }
}

module.exports = homeConfig

