import React, { useState } from 'react'
import howConfig from './howConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faRankingStar } from '@fortawesome/free-solid-svg-icons'

function How() {
    const [showFullText, setShowFullText] = useState(false)
    return (
        <div className='flex flex-col'>
            <div className='grid grid-cols-4 bg-white text-black text-lg px-4 items-center flex-col sm:rounded-md sm:flex-row'>
                <p className='col-span-3 py-4'>
                    {
                        showFullText ?
                            <span>
                                <p>{howConfig.offer.text}</p>
                                <FontAwesomeIcon icon={faMinus} className='text-blue-600' onClick={() => setShowFullText(!showFullText)}/>
                            </span>
                            : (
                                <span>
                                    <p>{howConfig.offer.text.slice(0,97)}</p>
                                    <i className='text-blue-600 mr-2 font-semibold'>Lees meer</i>
                                    <FontAwesomeIcon icon={faPlus} className='text-blue-600' onClick={() => setShowFullText(!showFullText)}/>
                                </span>
                            )
                    } 
                </p>
                <FontAwesomeIcon icon={faRankingStar} className='h-12 sm:h-1/2 mx-auto text-blue-600'/>
            </div>
            {Object.keys(howConfig).slice(1).map((configKey, index) => {
                return (
                    <Step stepConfig={howConfig[configKey]} index={index} />
                )
            })}
        </div>
    )
}

function Step({stepConfig, index}) {
    return (
        <div className={`w-full grid grid-cols-4 overflow-hidden py-8 ${index % 2 !== 0 ? 'bg-white text-black sm:rounded-md' : ''}`}>
            <div className='p-4 text-2xl sm:text-5xl font-bold my-auto flex sm:gap-x-8 col-span-2 sm:col-span-1'>
                <p className={index % 2 === 0 ? '' : 'text-blue-600'}>{stepConfig.title}</p>
                {stepConfig.icon && <FontAwesomeIcon icon={stepConfig.icon} className='h-8 sm:h-12 text-blue-600 my-auto mx-auto sm:mx-0'/>}
            </div>
            <div className='p-4 col-span-2 sm:col-span-3 flex gap-x-8'>
                <p className='my-auto text-xl'>{stepConfig.text}</p>
            </div>
        </div>
    )
}

export default How